:root {
    --blue: rgb(16, 41, 76);
    --dark-blue: #0d1623;
    --light-blue2: #007aff;
    --light-blue: #44A2F4;
    --light-grey: #dddddd;
    --lighter-grey: #eeeeee;
    --grey: #777777;
    --mid-grey: #aaaaaa;
    --mid-grey2: #444444;
    --mid-grey3: #2A2A2B;
    --dark-grey: #212121;
    --white-20: rgba(255,255,255,0.2);
    --black-20: rgba(0, 0, 0, 0.2);
  }


.modalContainer {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 0.3s ease;
}


.modal {
  /* border: 1px solid var(--white-20); */
  border-radius: 10px;
  width: 30vw;
  min-height: 330px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80px;
  background: var(--mid-grey3);
  padding: 0 0 0 0;
  box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
  font-size: 30px;
  text-align: center;
  align-items: center;  
}

.threeBtn .modal {
  grid-template-rows: 1fr 1fr;
}

.shareModal .modal{
  align-items: flex-start;
  justify-items: flex-end;
  grid-template-rows: 1fr;
  min-height: 300px;
}

.modal h3  {
  font-weight: 700;
  margin: 0 0 20px 0;
  font-size: 24px;
}

.modal p {
  line-height: 150%;
  margin-bottom: 0;
}

.modal .textContainer {
  padding: 0px 40px;

}

.modal .buttons {
  display: flex;
  gap: 10px;
}

.modal .buttons .btn1 {
  border: 1px solid var(--white-20)
}

.shareModal .textContainer .textInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shareModal .textContainer h3 {
  margin: 20px 0;
  text-align: left;
}

.shareModal .textContainer {
  width: calc(100% - 80px);
  height: 100%;
  display: grid;
  text-align: left;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;

}

.closeBtn {
  position: absolute;
  padding: 20px;

}

.errorRow span {
  font-weight: 400;
  margin: 0 0 0 0;
  font-size: 20px;
  color: var(--light-blue)
}

.modal p, .dropDownSelection {
  font-size: 18px;
}

.pageDropDown {
  width: 200px;
}

.modalBtn {
  margin: 20px 0 0 0;
}

.modal .dropDownField {
  left: 50vw;
}

.modal .dropDownChoice {
  width: 200px;
}

.settingsPageDropDown .dropDownChoice {
  width: 180px;
} 

.modal .dropDown {
  left: 150px;
}

.errorRow span:last-child {
    border-left: 1px solid var(--white-20);
}

.errorRow.oneBtn span:last-child  {
    border-left: none;
}


.errorRow {
    width: 100%;
    border-top: 1px solid var(--white-20);
    padding: 0px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}

.errorRow.oneBtn {
    grid-template-columns: 1fr;
}

.threeBtn .errorRow{
    grid-template-columns: 1fr; 
}

.threeBtn .errorRow span {
    border-bottom: 1px solid var(--white-20);
}

.threeBtn .errorRow span:last-child {
    border-left: none;
    border-bottom: none;
}

.errorRow span.saveContinue {
    background: var(--light-blue2);
    color: white;
}

.errorRow span {
    /* line-height: 300%;
    padding: 20px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 80px;
    /* height: 80px; */
}
input {
    font-family: "Roboto", sans-serif;
    width: calc(100% - 20px);
    background: rgba(255, 255, 255, 0.75);
    padding: 10px;
    border: 1px solid #fff;
    background: none;
    color: white;
}

input::placeholder {
    color: #aaa;
}


:focus {
    outline: none;
}