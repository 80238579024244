.container{
   display: inline-block;
   position: relative;
   transition: opacity 0.5s ease-in-out;
   opacity:0;
   overflow: hidden;
   top: 0;
   /* vertical-align: top; */
   /* transform:translate3d(0,0,0); */
   white-space: normal;
   font-size: 14px;
   display: flex;
   align-items: center;
   justify-content: center;
   /* flex-direction: column; */
   /* scroll-snap-align: both mandatory ; */
}

.container[loaded="true"] {
   opacity: 1;
   /* background: none; */
}

.container[loaded="false"]{
   opacity: 1;
}

/* .container[loaded="false"] {
   transition: all 0.3s ease;   
   background: linear-gradient(90deg, rgba(255, 0, 242, 0.85) 0%, rgba(2, 255, 230, 0.85) 100%);
   animation: gradient 1.25s ease-in-out infinite;
   background-size: 400% 100%;
} */

.mediaContainer {
   width: 100%;
   height: 100%;
   white-space: pre-line;
   top:0;
   left: 0;
   position: relative;
}

.mediaContainer:global(.arW_144) {
   aspect-ratio: 1.44;
   height: auto;
}

.mediaContainer:global(.arW_178) {
   aspect-ratio: 1.778;
   height: auto;
}

.mediaContainer:global(.arW_122) {
   aspect-ratio: 1.22;
   height: auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.indexContainer{
   position: absolute;
   height:100%;
   width: 100%;
}

.backgroundArt {
   position: absolute;
   height:100%;
   width: 100%;
   top: 0;
   left: 0;

}

.backgroundArt img {
   width: 100%;
   height: 100%;
   object-fit: cover;

}

@media only screen and (max-width: 1366px) and (orientation: landscape) {
   .backgroundArt img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
   }
 }
 