.rightNavMenu {
    width: calc(20% - 40px);
    position: fixed;
    top: 0;
    left: -28%;
    height: 100vh;
    z-index: 0;
    background-color: var(--primary-background);
    border-left: 1px solid var(--primary-accent);
    padding: 20px 20px 0 20px;
    /* border-radius: 29px 0 0 0; */
    display: flex;
    flex-direction: column;
    min-width: 19%;
    min-height: auto;
    padding-left: 2.1%;
    min-height: auto;
    width: 19%;
    -webkit-font-smoothing: antialiased;
    transition: all 0.3s ease;
    z-index: 0;
}

.rightNavMenu.navOpen {
    left: 0;
    padding: 0;
}

.rightNavMenuNav {
    position: fixed;
    top: 20px;
    width: calc(20% - 20px);
    z-index: 1;
    overflow-y: scroll;
}




.menuOn .rightNavMenuNav {
    /* background-color: var(--primary-background); */
    height: calc(100vh - 20px);
    /* box-shadow: 0 1px 1px rgba(0,0,0,0.25); */
}

.navBurger {
    position: absolute;
    top: 0px;
    right: 0px;
}

.navBurger:global(.round-btn) {
    width: 42px;
    height: 42px;
}

.menuOpen .navBurger svg{
    transform: scale(1,-1);
}

.dropdownHeading, .navHeading {
    width: calc(100% - 40px);
    background: var(--primary);
    border-radius: 20px;
    cursor: pointer;
    /* font-family: "CerebriSans", sans-serif; */
    font-family: "Gotham";
    font-size: 18px;
    /* font-weight: 600; */
    color: var(--primary-background);
    height: 44px;
    float: left;
    padding: 0 20px;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 4;
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
}

.menuOpen.navHeading {
    height: auto;

}

.menuOpen .moduleHeadingText {
    /* display: none; */
    box-shadow: 0px 2px 1px rgba(0,0,0,0.1);
    width: calc(100% - 40px);
    height: 42px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 20px;
    border-radius: 20px;
}

.moduleHeading {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 42px;
}


.navSub {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* margin-top: -43px; */
}

.headingOff .navHeading {
    display: none;
}

.dropdownHeading path{
    stroke: var(--primary);
}

.menuOn .dropdownHeading {
    background-color: var(--primary-background);
}

.vrLoadingContainer {
    background-color: var(--primary-background);
}


.dropdownHeadingBg {
    /* width: 100%;
    height: 80px;
    float: left;
    position: relative;
    background: red; */
}

.dropdownChoices {
    width: 100%;
    cursor: pointer;
    opacity: 0;
    height: 0;
    overflow: hidden;

}

.menuOn .dropdownChoices{
    opacity: 1;
    height: auto;
}

#dropDownChevron {
    width: 32px;
    height: auto;
    padding: 0 10px 0 10px;
    transform: scale(1,-1);
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 7px;
}

.menuOn #dropDownChevron {
    transform: scale(1,-1) rotate(-180deg);
}

.dropdownChoice {
    cursor: pointer;
    float: left;
    clear: left;
    margin: 0px 0 0px 0;
    padding: 10px 0;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    color: #000000;
    /* font-weight: 400; */
    transition: all 0.3s ease;
    opacity: 1;
    width: 100%;
    border-bottom: 0.5px solid var(--secondary);
}

.dropdownChoice:last-child {
    border: none;
}

.dropdownChoice.choiceOn {
    /* color: var(--primary-background) */
    /* font-family: "Gotham", sans-serif; */
}

.rightNavBtn, .rightNavBtn2 {
    cursor: pointer;
    /* background-color: #0F3557; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    /* height: 48px;
    width: 48px; */
}

.rightNavBtn path, .rightNavBtn2 path {
    fill: #fff;
}

.rightNavBtn2 {
    position: absolute;
    right: var(--sideIndent);
    bottom: var(--sideIndent);
    border-radius: 15px 0 0 0;
    right: 200px
}

.rightNavBtn2 svg{
    transition: all 0.3s ease;
    transform: rotateY(180deg);
}

.rightNavBtn2 path, .rightNavBtn2 line {
    fill: none !important;
    /* stroke: 1px solid # */
}

.rightNavBtn2.navOpen {
   
    right: 15%;
}

.rightNavBtn2.navOpen svg {
    transform: rotate(0deg);
   
}

.menuOpen .navBurger {
    /* filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.1)); */
}
/* Module */

.screensaverThumbnails {
    float: left;
    width: 100%;
    margin-top: 77px;
    height: calc(100% - 110px);
    overflow-y: scroll;
    padding-bottom: 50px;
    position: relative;
    z-index: 0;
    opacity: 1;
    transition: opacity 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.headingOff .screensaverThumbnails {
    margin-top: 30px;
    height: calc(100% - 30px);
}

.screensaverThumbnails::-webkit-scrollbar { display: none}

.screensaverThumbnail {
    float: left;
    width:100%;
    height: auto;
    /* padding: 5px 12% 5px 12%; */
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
    

}

.fade.screensaverThumbnail {
    opacity: 0.6;
}

.ready {
    
}

.rightNavMenu.menuOn .screensaverThumbnail {
    opacity: 0.5;
}

.screensaverThumbnail .thumbnail{
    float: left;
    width: calc(100% - 2px);
    height: auto;
    border-radius: 20px;
    border: 1px solid var(--primary)
}

.screensaverThumbnailActive .thumbnail{
    border: 1px solid var(--secondary);
}


.screensaverThumbnailOn {
    display: block;
}

.screensaverThumbnailOff {
    display: none;
}

.screensaverThumbnailActive {
    opacity: 1;
}


.screensaverThumbnailInactive {
    opacity: 0.6
}

.frame {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

.screensaverImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: none;
}

.swiperSlide.compareSlide .screensaverImage {
    width: auto;
    height: 100%;
    object-fit: none;
}

.screensaverVideo {
    width: 100%;
    height: 100%;
    /* height: calc(100% - 80px); */
    object-fit: cover;
    border: none;
}



.screensaveriFrame {
    width: 100% !important;
    height: calc(100% - 80px) !important;
    top: 0;
    border: none;
}

.fullFile {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.fullFile.fileOn {
    opacity: 1;
    z-index: 1;
}


.swiperSlide {
    width: 100%;
    height: 100vh;
    transition: opacity 0.3s ease;
}

.swiperSlide.compareSlide {
    display: flex;
    justify-content: center;
}

.swiperSlideOn {
    display: block;
}

.swiperSlideOff {
    display: none;
}

.screensaverControlsContainer {
    position: absolute;
    bottom: var(--sideIndent);
    z-index: 3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}

.screensaverControls {
    /* width: 141px; */
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));;
    /* background: #fff; */
    /* background-color: var(--secondary-background); */
    background-color: var(--round-btn-background-color);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    /* border: 1px solid #EFEFF0; */
    transition: all 0.3s ease;
    opacity: 0.5;
}

.screensaverControls.screensaverControlsWide {
    width: 171px;
}

.screensaverControls:hover {
    opacity: 1;
}

.screensaverControls.dimControls {
    opacity: 0.5;
}


.screensaverPrev, .screensaverNext, .screensaverFullScreen {
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.screensaverPrev, .screensaverFullScreen {
    border-right: 1px solid #e6e6e6;
}

.screensaverPrev svg {
    transform: rotate(180deg);
}

.screensaverNext svg {
    transform: rotate(0deg);
}

.screensaverNext svg path, .screensaverPrev svg path, .screensaverFullScreen svg path, .screensaverFullScreen svg line {
    stroke:var(--svg-stroke);
}

.screensaverNext:hover svg path, .screensaverPrev:hover svg path, .screensaverFullScreen:hover svg line {
    stroke:var(--svg-stroke-hover);
}

.screensaverGo, .startVRBtn {
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction:column;
    background: rgba(0,0,0,0.5);
    font-size: 24px;
    /* font-weight: 600; */
    font-family: "GothamBold";
    letter-spacing: 1px;
    /* text-transform: uppercase; */
}

.screensaverGo svg {
    width: 100px;
    height: auto;
   
}
/* .screensaverGo path {
    stroke: #fff;
}

.screensaverGo text {
    stroke: #fff;
    fill: #fff;
} */


.vrPopup, .vrPopup.vrPopupOff {
    position: fixed;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s ease;

}

.vrPopup.vrPopupOn {
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 1;
}


.vrCloseBtnContainer {
    /* left: 36px; */
}

.screensaverHandle {
    display: flex;
   align-items: center;
   justify-content: center;
    height: 100%;
}

.screensaverHandle.screensaverHandleVertical {
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.screensaverHandle .screensaverHandleButton {
    position: relative;
    z-index: 2;
}

.screensaverHandleLine {
    background: #fff;
    height: 100vh;
    width: 4px;
    position: absolute;
    z-index: 1;
}

.screensaverHandle.screensaverHandleVertical .screensaverHandleLine {
    background: #fff;
    height: auto;
    width: 100%;
    height: 4px;
    position: absolute;
    z-index: 1;
}

.screensaverHandle .screensaverControlsContainer {
    bottom: auto;
}

.screensaverHandle .screensaverControls {
    width: 80px;
   
}

.screensaverControlsContainer.controlsOff {
    opacity: 0;
}

.screensaverHandle.screensaverHandleVertical .screensaverHandleButton {
    bottom: 16px;
}

.screensaverHandle .screensaverNext, .screensaverHandle .screensaverPrev {
    padding: 0 5px;
}

:global(.presentationMode) .rightNavMenu{
    top:32%;
    bottom:0;
    height: auto;
}
:global(.presentationMode) .rightNavMenuNav{
    top:calc(32% + 20px);
    max-height: calc(74vh - 130px);
}

:global(.presentationMode) .dropdownChoices {
    max-height: calc(64.3vh - 130px);
    overflow-y: scroll;
}

:global(.serverApp) .vr{
    cursor: none;
}

:global(.serverApp) div[data-filetype="external"] {
    cursor: none;
  }

.landingContainer {
    width:100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;

}

  .itemsContainer {
    width:100%;
    display: grid;
    flex-wrap: wrap; 
    position: relative;
    align-content: flex-start;
    z-index: 0;
    margin-bottom:0px;
    padding-top: 160px;
    padding-bottom: 60px;
    height: calc(100vh - 161px);
    position: absolute;
    top: 0;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }

  .itemContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* aspect-ratio: 16/9; */
    /* width:384px;
    height: 247px; */
    cursor: pointer;
    /* aspect-ratio: 16/9; */
    /* width: auto; */
}

.itemContainer img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #878E91;
    border-radius: 20px;
    display: relative;
    transition: all .25s ease-in-out;
    overflow: hidden;
    aspect-ratio: 16/9;
}


  .heading{
    position:relative;
    color:var(--primary);
    top:56px;
    /* font-weight: 600; */
    font-family: "GothamBold";
    font-size: 28px;
    z-index: 3;
  }

  .letterbox{
    position: relative;
    width:85%;
    margin: 0 auto;
    z-index: 1;
}


.caption{
    color:var(--primary);
    width: 100%;
    text-overflow: ellipsis;
    /* margin-top:14px; */
    text-align: left;
    font-size: 16px;
    transition: all .25s ease-in-out;
    /* font-weight: 600; */
    font-family: "GothamMed";
    padding-top: 10px;
    float: left;
    
}

.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-position: cover;
}

.closeBtnContainer {
    z-index: 4;
 }

 :global(.serverApp) .closeBtnContainer {
    opacity: 0;
 }

 .vrCone {
    position: fixed;
    right: var(--sideIndent);
    /* bottom: var(--sideIndent); */
    width: 200px;
    /* height: 200px; */
    transform-origin: 50% calc(100% - 13px);
 }

 .vrRefImg {
    position: fixed;
    right: var(--sideIndent);
    bottom: var(--sideIndent);
    width: 200px;
    height: auto;
    /* box-shadow: 0 6px 8px rgba(0,0,0,0.5); */
 }

 .vrRefImg img {
    width: 200px;
    height: auto;
 }
/* @media screen and (max-width: 2048px) {


    .itemContainer{
        width:calc(384px*.8);
        height:calc(247px*.8);  
    }
} */


@media screen and (max-width: 1680px) {

    .navHeading, .dropdownChoice {
        font-size: 20px;
    }

    .checkCircle, .categoryLabel {
        font-size: 18px;
    }
    
    .rightNavMenu {
        min-width: 280px;
    }

    .rightNavMenuNav {
        min-width: 280px;
    }
    
    .controllerNav.rightNavMenu {
        min-width: 100%;
    }
    
    .controllerNav .rightNavMenuNav {
        min-width: 0;
        max-width: 200px;
    }
    
    
    

}


@media screen and (max-width: 1440px) {

    .navBurger {
        top: 4px;
        right: 0;
        left: 78%;
    }

    /* .itemContainer{
        width:calc(384px*.9);
        height:calc(247px*.9);  
    } */

    .navHeading,  .dropdownHeading, .dropdownChoice {
        font-size: 18px;
    }

    .checkCircle, .categoryLabel {
        font-size: 16px;
    }
}



@media screen and (max-width: 1366px) {

    .navBurger {
        top: 12px;
        right: 0;
        left: 78%;
    }
    
    .itemContainer{
        width:calc(384px*.8);
        height:calc(247px*.8);  
    }
    
    .dropdownChoice:hover, .dropdownChoice::active {
        font-weight: 400;
    }
}