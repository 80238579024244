@import './system/styles/funcs.scss';

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./assets/fonts/Gotham/Gotham-Book.otf);
  font-weight: 300;
}

@font-face {
  font-family: 'GothamMed';
  src: local('GothamMed'), url(./assets/fonts/Gotham/Gotham-Medium.otf);
  font-weight: 500;
}


@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'), url(./assets/fonts/Gotham/Gotham-Bold.otf);
  font-weight: 700;
}


* {
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;    
}


html,
body {
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
  background-color:rgba(20,20,20,1);
  // background-color: fuchsia;
  font-family:'Gotham', Arial, Helvetica, sans-serif;
  margin:0;
  padding:0;
  height:100%;
  width:100%;
  overflow: hidden; 
  font-smooth:always;
  
  color:white;
  cursor:default;  
  overscroll-behavior: none;
  // scroll-snap-type: none;
  -webkit-font-smoothing: antialiased;
}
#root{
  min-width:850px;
  min-height: 600px;
  overscroll-behavior: contain;
  /* overflow: scroll; */
}

#root.receiveMode {
  pointer-events: none;
}

h4 {
  font-family: "GothamBold";
  font-weight: 700;
}


body::-webkit-scrollbar,
#root::-webkit-scrollbar,
.hide-scroller::-webkit-scrollbar { display: none}


img{
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} 
.fullscreen{
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
}
.fcenter{
  display: flex;
  align-items: center;
  justify-content: center;
}
.force3d{
  transform: translateZ(0);  
  // perspective: 1000px;
}
:root{
  --color :#000000;
  --primary:#000000;
  --secondary:#FFFFFF;
  --background:#FFFFFF;
  --primary-accent:#007aff;
  --primary-font:'Gotham', 'Gotham';
  --primary-font-color:#000000;
  --primary-font-weight:400;
  --secondary-background:#FFFFFF;
  --svg-stroke :var(--primary);
  --svg-stroke-hover :var(--primary-accent);
  --round-btn-background-color :var(--secondary-background);
  --sideIndent: calc(24px * var(--scaleiPad));
  --narrative-background:#FFFFFF;
}

.root-portal {
  z-index: 1;
}
.outter-round-btn{
  cursor: pointer;
  color: var(--primary);
}
.outter-round-btn:hover{
  color: var(--primary-accent);
}
.outter-round-btn:hover .round-btn{
  transform: scale(1.15);
  
}


.round-btn{
  pointer-events: all;
  width:scaleValue(38px,42px);
  height:scaleValue(38px,42px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: drop-shadow(0 3px 1px rgba(0,0,0,0.1));
  margin-bottom:calc(12px * var(--scaleiPad)); 
  background-color: var(--round-btn-background-color);
  transition: transform 0.1s ease-in-out;
}

// .ipad .round-btn{
//   filter:none;
//   box-shadow: 0 3px 1px rgba(0,0,0,0.1);
// }

.round-btn.shadow{
  filter: drop-shadow(0px 3px 1px rgba(0,0,0,1));
}

.round-btn svg{
  // width:calc(15px * var(--scaleiPad));
  transition: transform 0.25s ease-in-out;
  width:100%;
  height: 100%;

}

.round-btn svg.gMat {
  max-width: 30px;
}

.round-btn.small svg{
  height:67%;
  width: auto;
}

@media (hover: hover) {
  .round-btn:hover{
      color: var(--svg-stroke-hover)
    // transform: scale(1.15);
    // filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5));
  }
  .round-btn:hover  path,
  .round-btn:hover  circle,
  .round-btn:hover  line{
    stroke: var(--svg-stroke-hover) ;
  }

  .round-btn:hover .gMat  path,
  .round-btn:hover .gMat  circle,
  .round-btn:hover .gMat  line{
    fill: var(--svg-stroke-hover) ;
  }

}

.round-btn:active{
  // transform: scale(1.15);
  // filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5));
}

.round-btn  path,
.round-btn  circle,
.round-btn  line{
  // stroke:var(--svg-stroke);
  transition: all 0.25s ease-in-out
}
// .round-btn:hover path{
//   // fill:var(--hover) !important;
// }
.round-btn:last-child{
  margin-bottom: 0;
}
.buttonContainer{
  position: absolute;
  bottom: var(--sideIndent);
  left: var(--sideIndent);
  display: flex; 
  pointer-events: none;
  align-items: flex-end;
}
.buttonContainerCol{
  display: flex;
  // flex-direction: column;
  gap: 18px;
  margin-right: 18px;
}

.buttonContainer .round-btn {
  margin-bottom: 0;
}

.genericScale{
  transform-origin: top left;
  transform:scale(calc(calc(1.0 + calc(0.4 * var(--lerpAspect))) * var(--lerpScale)));
}

video::-webkit-media-controls {
  // display:none !important;
}

::placeholder {
  color:rgba(15,53,87,0.5)
}

.themeSVGStroke .themeable
{
  stroke: var(--svg-stroke);  
}

.themeSVGFill .themeable {
  fill: var(--svg-stroke);
}

.denied-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.hous-floor-plans-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  // BACKGROUND-COLOR: rgba(79, 99, 115, 0.6);
}
.hous-floor-plans-container .btn {
  pointer-events: all;
  font-weight: 300;
  color:rgba(0, 0, 0, 0.5);
  padding: 0 25px 0 25px;
  height: 50px;
  border: 1px solid silver;
  font-size: 18px;
  text-align: center;
  min-width: 100px;
  margin-left: 25px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
  letter-spacing: 1px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hous-floor-plans-container .btn:hover{
  background: #DDD;
  
}

.hous-floor-plans-container .btn:active {
  opacity: 0.65;
}
#hous-pdf-preview {
  background-color: #000000;
 
  z-index: 100;
}
#hous-pdf-preview .frame-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 100px;
}
#hous-pdf-preview .round-btn{
  position: absolute;
  left:var(--sideIndent);
    bottom: var(--sideIndent);
    cursor: pointer;
}
.ipad #hous-pdf-preview .frame-container {
  bottom: 60px;
}
#hous-pdf-preview iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ipad #hous-pdf-preview iframe {
  min-width: auto;
  min-height: auto;
}

.serverApp .ifServerHide{
  display: none;
}

.controllerApp .ifControllerHide{
  display: none;
}

// .electronApp .App {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// }

// .electronApp #main.fullscreen {
//   aspect-ratio: 1.78;
//   height: auto;
//   position: relative;
//   overflow: hidden;
// }

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.deviceSpan {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#main.fullscreen {
  // aspect-ratio: 1.78;
  height: 100%;
  width:100%;
  position: relative;
  overflow: hidden;
}

#main.fullscreen.letterBoxed {
  height: auto;
}

.controllerApp #main.fullscreen, #main.fullscreen.touchDevice {
  aspect-ratio: auto;
  height: 100%;
}


.fade-page-appear,
.fade-page-enter {
opacity: 0;
z-index: 1;
}
.fade-page-appear-active,
.fade-page-enter.fade-page-enter-active {
opacity: 1;
transition: opacity 450ms linear 250ms;
}
.fade-page-exit {
opacity: 1;
}
.fade-page-exit.fade-page-exit-active {
opacity: 0;
transition: opacity 150ms linear;
}


.serverApp .App, .receiver .App{
  cursor: none;
}

.serverApp .mapboxgl-canvas-container.mapboxgl-interactive, .receiver .mapboxgl-canvas-container.mapboxgl-interactive, .serverApp img{
  cursor: none !important;
}

.tokenUser {
  display: none;
}

.genericLogin .logo path{
  fill: white;
}

.loadingPage {
  background: black;
}

.noAnim {
  transform: translate(0,0) !important;
  opacity: 1 !important;
  scale: 1 !important;
}


#remoteCursor {
  // border-radius: 40px;
  // width: 10px;
  // height: 10px;
  // border: 2px solid fuchsia;
  // display: block;
  // z-index: 10;
  // position: fixed;
}



.canvasImg.bkg {
  top: 0 !important;
  width: 100vw !important;
  object-fit: cover !important;
  min-width: 100%;
  height: 100%;
}

.letterBoxed .canvasImg.bkg {
  height: auto;
  width: 100% !important;
}

.arBox {
  width: 100%;
  // object-fit: cover;
  height: 100%;
  // outline: 1px solid white;
  animation: pulse 2s infinite;
  transition: background 0.2s ease;

}

.Page {
  // opacity: 0;
}

.Page[loaded="true"] .arBox {
  background: transparent;
  animation: none;
  transition: background 0.2s ease;
}

.arBoxInner {
  width: 100%;
  object-fit: cover;
  height: 100%;
  opacity: 0;
  float: left;
}

.arBox img {
  width: 100%;
  height: 100%;
}

// .letterBoxed .arBox {
//   height: auto;
//   width: 100% !important;
// }

@keyframes pulse {
  0%, 100% {
    background-color: rgba(200,200,200,0);
  }
  50% {
    background-color: rgba(200,200,200,0.05);
  }
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
  // .canvasImg.bkg {
  //   top: 0 !important;
  //   width: 100vw !important;
  //   object-fit: cover !important;
  //   min-width: 100%;
  //   height: 100vh !important;
  // }
}

@media only screen and (max-width: 900px) {
    
  .fullscreen.narrative-holder {
      display: flex;
      align-items: center;
      // flex-direction: column;
  }

  .narrativeContainer, .containerScroll {
      aspect-ratio: 1.778;
      height: auto;
      width: 100%;
      // background-color: var(--narrative-background);
  }

  .Page {
    
  }



    .canvasImg.bkg {
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
      // position: absolute;
      // top: 0;
      // left: 0;
      // transform: scale(1);
      // width: 100vw;
      // height: 100vh;
      // object-fit: cover;
      // position: absolute;
      // top: 0;

      
    }

    .canvasImg {
      // max-width: 100%;
      // max-height: 100%;
      // object-fit: contain;
    }

    // .canvasImg.bkg {
    //   max-width: 100%;
    //   max-height: 100%;
    //   object-fit: contain;
    // }

    // .canvasImg {
    //   transform: scale(0.15);
    // }

  // .containerScroll {
      
  // };


}