@import './funcs.scss';

.settingsContainer{
    position: relative;
    opacity: 0;
    display: none; //start off hidden
    overflow-y: scroll;
    max-height: 64vh;
    
}

.heading{
    padding: 5px;
    font-size: 30px;
    height: scaleValue(25px,35px);
    font-weight: 400;
    cursor: pointer;
    color:#ffffff;
    white-space: nowrap;
    display: flex;
    align-items: center;
    display: flex;
    align-content: space-between;
}
.headingTitle{
    width: 100%;
}

.settingsContainer :global(p){
    color:#ffffff;
    font-size: 16px;
    margin:17px 0;    
}

h4, u {
    color:#ffffff;
}

.logoutContainer{
    color: var(--primary);
    position: relative;
    display: block;
    cursor: pointer;
    margin: 10px 0 10px 0;;
   float: left;
   clear: left;
}

.logoutContainer :global(.round-btn){
    padding: 0px scaleValue(16px,20px);
    width: auto;
    font-size: scaleValue(14px,16px);
    border-radius: scaleValue(16px,21px);
    margin: 30px 0 40px 0;
}

:global(.controllerApp) .logoutContainer :global(.round-btn){
    display: none;
}


.buildAbout {
   width: 100%;
   float: left;
   display: flex;
   justify-content: space-between;

   margin: 10px 0 40px 0;
    border-top: 0.5px solid white;
    padding-top: 20px;
}

.buildAbout p {
    font-size: 14px;
    margin: 0 0px 0 0;
}

.deletion h4 {
    padding: 0 0 0 0;
    margin: 0;
}

.prefs {
    float: left;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pref {
    display: flex;
    justify-content: space-between;
    float: left;
    gap: 20px;
    align-items: center;
    width: 100%;
    
}

.prefs h2 {
    font-size: 18px;
    // margin-bottom: 30px;
}

.prefs h3 {
    font-size: 14px;
}