@import './funcs.scss';

.container{
    display:flex;
    background-color:rgba(20,20,20,1);
    align-content: flex-start;
    align-items: flex-start;
    --left-margin:32px;
    opacity:0;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.containerBox {
    max-width: 1920px;
    width: 100%;
    aspect-ratio: 1.778;
}


.container.loading {
    pointer-events: none;
}

.logo{
    height: 33px;
    width: auto;
    top: 44px;
    position: absolute;


}

.logo path {
    fill: #ffffff;;
}

.slogan{
    /* margin : 0;71px 50px 34px 31px; */
    font-size: 50px;
    // line-height: 40px;
    font-family: "GothamBold";
    font-weight: 700;
    color: #0F3557;
    position: relative;
    width: 100%;
    height: auto;
    margin:calc(20px * var(--scaleiPad)) 0;
    pointer-events: none;
    justify-self: baseline;
    // opacity: 0;
}
.slogan p{margin:0;}

.column[open] .slogan{
    opacity: 0;
}
.backgroundVideo,
.backgroundImage{
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    object-fit: cover;
}
.backgroundVideo[open],
.backgroundImage[open]{
    filter:blur(10px) ;
    opacity: 0.4; 
}


/* @media only screen and (max-aspect-ratio:144/100) {
    .container{
        --left-margin:24px;
    }
} */

.columnRow {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 1fr;

}

.column{
    // position: relative;
    // width:calc(24.1% + calc(-0.1% * var(--lerpAspect)));
    // min-height:74%;
    // padding-left: 1.25%;
    // // flex-grow: 1;
    // // flex-shrink: 1;
    // // flex-basis: auto;
    // margin-right: 15px;
    // transition: all 2s;flex-shrink: 1;
}

/* @media only screen and (max-aspect-ratio:144/100) {
    .column{
        width: 25%;
    }
} */

.column1{
    display: flex;

    flex-direction: column;
    min-height: auto;
    padding: 0 2%;
    // align-items: flex-start;
    justify-content: center;
    min-height:auto;
    width: 96%;
    min-height: 100vh;
    transition: opacity 0.3s ease;
    opacity: 1 !important;

}

.column1:global(.settings) .column1Container{
    width:365px;
}

.column1:global(.settings) .columnRow {
    grid-template-columns: 400px 1fr;
    margin-left: 2%;
}

.column1:global(.search){
    /* transition: width 0.35s ease-in-out 0.25s; */
    width:scaleValue(341px,470px)
}
.column1 .spacer{
    flex:1;
    pointer-events: none;
}
.column1[open]{
    height:100%
}

.column1Container{
    display: none;
    position: relative;
    width: 90%;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
    z-index: 2;
    overflow-y: scroll;

    /* margin:calc(20px * var(--scaleiPad)) 0; */
}

.column1Container h1 {
    font-size: 24px;
    color:#ffffff;
    font-weight: 500;
    // margin: 0;
    // padding: 0;
    // padding-bottom: 10px;
}

.column[open] .column1Container{
    display: block;
}

.menuGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    transition: all 0.3s ease;
    gap: 28px
}

.menuGrid.largeGrid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    transition: all 0.3s ease;
}

.columnHeading{
    margin-top: 350px;
    font-size: 2em;
}

.columnItem{
    // padding: scaleValue(10px,15px) 0px scaleValue(10px,15px) 0; 
    // font-size: scaleValue(18px,28px);
    font-size: clamp(18px, 0.016vh, 28px);
    // height: scaleValue(25px,35px);
    font-weight: 400;
    // float:left;
    // clear: both;
    cursor: pointer;
    color:#0F3557;
    opacity: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    flex-direction: column;
    width: 100%;
 
    // font-size: 22px;
}

.columnItem:last-child {
    padding-bottom: 120px;
}


.columnItem h2 {
    font-size: scaleValue(16px,18px);
    font-weight: normal;
    width: 100%;
    color:#adadad;
    // text-align: center;
}

.columnThumb {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 20px;
    height: auto;
    // border: 1px solid #555;
    // box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

:global(.presentationMode) .columnItem {
    white-space: normal;
    height: auto;
}
:global(.serverApp) .columnItem {
    cursor: none;
}



.columnItem :global(span){
    height: 85%;
}

.columnItem .narrativeHeading{
    height: 85%;
    max-height: 40px;
    text-wrap: wrap;
}

.columnItem :global(svg){
    height: 100%;
}
.subColumnItem{
    margin-left:32px;
}

.columnItem:first-of-type{
    padding-top: 0;
}
/* @media only screen and (max-aspect-ratio:144/100) {
    .columnItem{
        font-size: 20px;
        padding: 5px 0px ; 
    }
} */
.columnItem[active="true"]{
    font-weight: 600;
    color:rgba(255, 76, 85, 1);
    
}
@media (hover: hover) {
    .columnItem:hover{
        // opacity: 0.75;
        color:rgba(255, 76, 85, 1)
    }
}
.columnItem:active{
    // opacity: 0.5;
    color:rgba(255, 76, 85, 1)
}


.headingRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchContainer{
    width: 360px;
    position: absolute;
    background-color: white;
    border-radius: 40px;
    border: 1px solid #E6E6E6;
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    align-items: center;
    padding: 0 20px;
    height: 42px;
    font-size: 24px;
    top: 44px;
    right: 2%;
    // display: flex;
    // margin-bottom:scaleValue(22px,28px);
}

:global(.controllerApp) .searchContainer {
    display: none;
}

.searchIcon {
    width: 32px;
    height: 32px;
    position: relative;
    left: -10px;
}

.closeIcon {
    width: 32px;
    height: 32px;
    position: relative;
    right: -10px;
}

.searchFieldContainer {
    height: 42px;
    display: flex;
    align-items: center;
}



.searchInput[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-color: transparent; 
}
.searchInput{
    border: none;
    // width:80%
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    color:var(--primary);
    -webkit-appearance: none;
}

.searchInput:autoFill {
    -webkit-text-fill-color: var(--primary);
    -webkit-box-shadow: none;
    background-color: transparent;
    // background-color: transparent !important;
    // color:var(--primary) !important;
} 

.searchInput:focus{
    outline: none;
}

:global(.ipad) .searchInput{
    -webkit-user-select: text;
    user-select: text;
}


.narrativeSwipe1{
    position: absolute;
    top:0;
    right:100%;
    height:100%;
    width:35%;
    background-color: rgb(239,239,239);
}
.narrativeSwipe2{
    top:0;
    right:100%;
    position: absolute;
    height:100%;
    width:20%;
    background-color: rgb(135,142,145);
}
.narrativeSwipe3{
    top:0;
    right:100%;
    position: absolute;
    height:100%;
    width:10%;
    background-color: rgb(0,195,46);
}

.myProperties{
    border-top: 1px solid #878E91;
    width:100%;
    padding-top: 33px;
    margin-top: 33px;
    font-weight: 600;
}
.myProperties:global(.selected){
    color:rgba(255, 76, 85, 1)
}

.settingsContainer{
    color:var(--primary);
    position:relative;
}
.settingsContainer:hover {
    color:var(--primary-accent)
}
.settingsContainer :global(.round-btn){
    padding: 0px scaleValue(16px,20px);
    width: auto;
    font-size: scaleValue(14px,16px);
    border-radius: scaleValue(16px,21px);
};


.helpContainer{
    // position: absolute;
    // top:32px;
    // right:32px; 
    opacity: 1;
    
}
.helpContainer :global(.round-btn){
    opacity: 0;
    // scale:0.1;
}

.helpContainer[open]{
    pointer-events: none;
  
}

.helpCtaContainer{
    position: absolute;
    top: scaleValue(64px,68px) ; ;
    right:0;
    width:180px;
    color:var(--primary);
    font-size: 18px;
    background-color: white;
    border: 1px rgba(135,142,145, 0.34);
    border-radius: 2px;
    padding:12px 16px;
    white-space: pre;
    filter: drop-shadow(0px 3px 20px rgba(0,0,0, 0.34));
    line-height: 22px;
    text-align: right;
    opacity: 0;
    transform: scale(0.5);
}

.helpCtaArrow{
    position: absolute;
    width: scaleValue(25.4px,29.7px) ; 
    height: scaleValue(25.4px,29.7px) ; 
    border-left: 1px solid rgba(135,142,145, 0.34);
    background-color: white;;
    border-top  :  1px solid rgba(135,142,145, 0.34);
    position: absolute;
    transform: rotate(45deg);
    transform-origin: top right;
    right: 0;
    top:0;
    z-index: -1;  
}

.versionNote {
    position: absolute;
    bottom:30px;
    right:30px;
    z-index: 10;
}

:global(.serverApp) .buttonContainerCol{
 display: none;
}



:global(.presentationMode) .container{
    align-items: flex-end;
}
:global(.presentationMode) .column{
  min-height: 33%;
}
:global(.presentationMode) .column1{
    // border-radius: 0  calc(29px * var(--scaleiPad)) 0 0;
    border-radius: 0;
    padding-left: 130px;
    padding-bottom: 20px;
}
:global(.presentationMode) .column1Container{
    margin-top: 20px;
    width: calc(85% - 120px);
}
:global(.presentationMode) .column3,
:global(.presentationMode) .column2,
:global(.presentationMode) .column4{
    min-height: 68%;
    max-height: 68%;
    overflow: hidden scroll;
    top:0;
    height: auto;
}
:global(.presentationMode) .column4{ border-left:1px solid #878E91;}
:global(.presentationMode) .column4Border{
    display: none;
}
// :global(.presentationMode) :global(.settings){
//     // display: none;
// }
.presentationLogout{
    position: absolute;
    font-size: 20px;
    right:160px;
    bottom: 32px;
    width:104px;
    height: 42px;
    color:var(--primary);
    background:white;
    border-radius: 21px ;
}

.presentationQuit{
    position: absolute;
    font-size: 20px;
    right:32px;
    bottom: 32px;
    width:104px;
    height: 42px;
    color:var(--primary);
    background:white;
    border-radius: 21px ;
}

.narrativeHeading {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    color: white;
    margin-top: 10px;
    height: 20px;
}

.narrativeHeading  h2 {
    padding: 0;
    margin: 0;
    height: 40px;
    display: flex;

}

// .narrativeHeading :global(path) {
//     fill: white;
// }

// .narrativeHeading .downloaded :global(path) {
//     fill: green;
// }

.downloadIcons {
   display: flex;
   gap: 10px;
}

.columnItem .downloadIcon {
    width: 40px;
    height: 40px;
    transition: all 0.2s ease;
}

.columnItem .downloadIcon:hover {
    border-radius: 40px;
    background: rgba(255,255,255,0.1);
}

.columnItem .updateBtn {
    border: 4px solid rgb(61, 145, 255);
    border-radius: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    font-size: 12px;
    font-weight: 700;
    color: #3D91FF;
    transition: all 0.2s ease;
}

.columnItem .updateBtn:hover {
    background: rgba(255,255,255,0.1);
    // color: white;
}

.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(20,20,20);
    z-index: 3;
}


@media screen and (max-width: 1400px) {
.columnItem .downloadIcon {
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
    align-items: center;
    justify-content: center;
    display: flex;

}

.columnItem .downloadIcon:hover {
    border-radius: 30px;
    background: rgba(255,255,255,0.1);
}

.columnItem .updateBtn {
    border: 3px solid rgb(61, 145, 255);
    border-radius: 30px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-size: 12px;
    font-weight: 700;
    color: #3D91FF;
    transition: all 0.2s ease;
}

.columnItem .updateBtn:hover {
    background: rgba(255,255,255,0.1);
    // color: white;
}
}

@media screen and (min-width: 2400px) {

    .menuGrid {
        grid-template-columns: repeat(4, 1fr);

    }
}
